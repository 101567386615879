<template>
  <div class="office-root" v-loading="loading">

    <div id="office-container1" style="height: 100vh;"></div>
  </div>
</template>

<script>
  //import request from '@/utils/request'
  // import {findDocLastFileById} from "@/api/file.js"
  //import guid from "@/utils/guid";

  export default {
    name: 'office-online',
    props: {
      value: { //docId
        type: [String, Number],
        default: "0"
      },
      isView: {
        type: Boolean,
        default: false
      },
      item: {
        type: Object,
        default: {}
      },
    },
    model: {
      event: "changed",
      prop: "value"
    },
    computed: {
      deptId() {
        return this.$store.getters["user/deptId"]
      },
      userId() {
        return this.$store.getters["user/id"]
      },
      deptName() {
        return this.$store.getters["user/deptName"]
      },
      userName() {
        return this.$store.getters["user/name"]
      },
      token() {
        return this.$store.getters["user/token"]
      }
    },
    watch: {

    },
    data() {
      let that = this;
      return {
        callbackUrl: "https://nm.app.vc14.com/api/recods/updateFile/986",
        selfValue: that.value,
        filePath: null,
        fileName: null,
        docEditor: null,
        config: {},
        loading: false,
        fileType: null,
      };
    },
    methods: {
      initData() {

        // 文件访问地址前缀，确保此地址与office服务器网络相通
        console.log(this.$route.query)

        this.fileType = this.$route.query.extension

        this.filePath = this.$route.query.url;
        this.fileName = this.$route.query.original_filename

        this.initOffice();
      },
      initOffice() {
        //  office回调地址前缀，确保此回调地址与office服务器网络相通
        let officeBaseUrl = process.env.VUE_APP_OFFICE_BASE_URL;
        //   当前登录用户的token
        let currentUserToken = this.token;

        if (currentUserToken) {

        }

        //  office回调地址
        let {
          callbackUrl
        } = this

        var onRequestHistoryClose = function() {
          document.location.reload();
        };

        var onRequestHistory = function() {

        }

        this.config = {
          //type:"mobile",
          editorConfig: {
            lang: 'zh-CN',
            mode: this.$route.query.isView ? 'view' : 'edit',

            customization: {
              forcesave: 'true',
              autosave: 'true'
            },


            callbackUrl: "https://nm.app.vc14.com/recods/updateFile/" + this.$route.query.id,
            //  ID换成用户id，name换成用户昵称
            user: {
              group: this.deptName,
              id: this.userId,
              name: this.userName + "(" + this.deptName + ")"
            }
          },

          document: {
            //  根据具体类型为fileType赋值，如docx,xlsx
            fileType: this.fileType,
            //  key替换为文件id
            key: this.$route.query.md5 || this.$route.query.id,
            //  建议取文件名
            title: this.fileName,
            url: this.$route.query.file_url || this.$route.query.url,
            permissions: {
              reader: true,
              download: true,
              edit: true,
              print: true,
              review: true,
              rename: true,
              changeHistory: true,
              fillForms: true,
              comment: true
            },
          },
          //TODO documentType: excel,word
          //documentType:	"word",
          "lang": "zh-CN",
          "location":"zh-CN",
          "events": {
            "onRequestHistory": onRequestHistory,
            "onRequestHistoryClose": onRequestHistoryClose,
          },
          // "customization": {
          //   "chat": true,
          //   "comments": true,
          //   "goback": true,
          //   "compactToolbar": true,
          //   "about": false,
          //   "leftMenu": true,
          //   "rightMenu": false,
          //   "toolbar": true,
          //   "header": true,
          //   "autosave": true,
          //   "showReviewChanges": true,
          //   "forcesave": true,
          // }
        }
        this.docEditor = new DocsAPI.DocEditor('office-container1', this.config);
        // this.docEditor.setReferenceData({
        //      "fileType":  this.fileType,
        //      "path": this.fileName,
        //      "url": this.$route.query.file_url
        //  });
      }
    },
    mounted() {

      if (this.value != '0') {
        this.initData(this.value);
      } else {
        this.initData();
      }
      debugger
    },

    beforeDestroy() {
      if (this.docEditor) {
        this.docEditor.destroyEditor();
      }

    }
  };
</script>

<style scoped lang="scss">
  .office-root {
    height: 100vh;

    #office-container {
      height: 100vh;
    }
  }
</style>
